import React, { Suspense, lazy, useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, Theme, Typography } from '@mui/material';
import { ThemeProvider } from '@mui/system';
import { Route, Router, Routes, useLocation } from 'react-router';
import Login from './pages/login/Login';
import NotFound from './components/navigation/NotFound';
import RequireAuth from './components/navigation/permissions/RequireAuth';
import { useWindowSize } from 'react-helpers';
import Loader from 'react-helpers/src/components/Loader';
import { DEFAULT_FONT_SIZE, MOBILE_FONT_SIZE } from './styles/styles';
import * as environment from './environments.json';
import useNavigationStore from './store/useNavigationStore';
import BackgroundProvider from './BackgroundProvider';
import RegisterDiscord from './pages/register-discord/RegisterDiscord';
import RegisterDiscordSuccess from './pages/register-discord/RegisterDiscordSuccess';
import DiscordInfo from './pages/register-discord/DiscordInfo';
import RegisterDiscordMagicLink from './pages/register-discord/RegisterDiscordMagicLink';
import LinkDiscordWithMagicLink from './pages/register-discord/LinkDiscordWithMagicLink';
import useNotistack from 'react-helpers/src/hooks/useNotistack';

const Root = lazy(() => import('./pages/Root'));

const App = () => {
    const { isMobile } = useWindowSize();
    const { railRoutes } = useNavigationStore();
    const { Toaster } = useNotistack();
    const theme = createTheme({
        typography: {
            fontFamily: ['Inter'].join(','),
            fontSize: isMobile ? MOBILE_FONT_SIZE : DEFAULT_FONT_SIZE,
        },
        palette: {
            mode: 'dark',
            background: {
                default: '#000000',
                paper: '#343434',
            },
            primary: {
                main: '#ffffff',
                contrastText: '#000000',
            },
            secondary: {
                main: '#F01CA0',
            },
            success: {
                main: '#A0F01C',
            },
            error: {
                main: '#ff3333',
            },
            info: {
                main: '#2C4770',
            },
            warning: {
                main: '#91A437',
            },
            text: {
                primary: '#fff',
                disabled: '#666',
            },
        },
    });

    return (
        <BackgroundProvider>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Suspense fallback={<Loader />}>
                    <Routes>
                        <Route path="login" element={<Login />} />
                        <Route path="discord" element={<DiscordInfo />} />
                        <Route path="register-discord" element={<RegisterDiscord />} />
                        <Route path="register-discord-success" element={<RegisterDiscordSuccess />} />
                        <Route path="register-discord-magic-link" element={<RegisterDiscordMagicLink />} />
                        <Route path="link-discord-with-magic-link" element={<LinkDiscordWithMagicLink />} />
                        <Route path="/*" element={<Root />} />
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                </Suspense>
                <Toaster position="bottom-center" reverseOrder={false} />
            </ThemeProvider>
        </BackgroundProvider>
    );
};

export default App;
