import React, { useEffect } from 'react';
import Logo from '../../assets/icons/BackgroundLogo';
import Button from '../../components/button/Button';
import LoginText from '../../assets/icons/LoginText';
import LoginArrow from '../../assets/icons/LoginArrow';
import { useMutation } from '@tanstack/react-query';
import { login, loginRequest, registerDiscord, Tokens } from '../../services/auth.service';
import { AxiosError } from 'axios';
import { useMsal } from '@azure/msal-react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useNotistack from 'react-helpers/src/hooks/useNotistack';

export type RegisterDiscordForm = {
    type: string;
    token: string;
    discordUserId?: string;
};

const DiscordInfo = () => {
    const { instance } = useMsal();
    const [searchParams, setSearchParams] = useSearchParams();
    const { showError, showAxiosError } = useNotistack();
    const navigate = useNavigate();

    return (
        <div className="flex w-full h-screen container mx-auto">
            <div className="w-full h-full">
                <div className="absolute top-0 left-0 z-0">
                    <Logo />
                </div>
                <div className="flex flex-col items-center z-10 justify-center space-y-20 h-full">
                    <h1 className="font-ivyora text-4xl px-4 text-center">
                        Join our Discord channel with the invite link below!
                    </h1>
                    <Button
                        size="large"
                        variant="primary"
                        className="z-10"
                        onClick={() => {
                            window.open('https://discord.gg/QMaPYGxUs6');
                        }}
                    >
                        Go to the VITL Discord!
                    </Button>
                    <div className="flex flex-col items-center justify-center z-10 px-10 text-center text-sm">
                        <p>
                            For help, refer to{' '}
                            <a
                                href="https://vitl-power.gitbook.io/vitl-discord-docs/how-do-i-create-a-new-discord-account"
                                className="underline"
                                target="_blank"
                            >
                                these docs on how to make a Discord account.
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DiscordInfo;
